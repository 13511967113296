@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  font-family: 'Inter', sans-serif;
  color: #2a2626;
  min-height: 100vh;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  animation: none !important;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0) inset;
}

.ant-picker-input,
.ant-picker-input-active {
  fill: #956868;
}

.ant-picker-range,
.ant-picker-suffix svg {
  fill: #956868;
}

.ant-picker-range .ant-picker-active-bar,
.nt-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-selected
  .ant-picker-cell-inner,
:where(.css-dev-only-do-not-override-1me4733).ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-start
  .ant-picker-cell-inner,
:where(.css-dev-only-do-not-override-1me4733).ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-end
  .ant-picker-cell-inner {
  background-color: #9c6742;
}
.ant-picker-cell .ant-picker-cell-in-view .ant-picker-cell-range-hover,
.ant-picker-cell-in-view
  .ant-picker-cell-in-range
  .ant-picker-cell-range-hover-start,
.ant-picker-cell .ant-picker-cell-in-view .ant-picker-cell-range-hover-end {
  border-color: #956868;
  background-color: #9c6742;
}
.ant-picker-dropdown .ant-picker-header-view button:hover {
  color: #956868;
}

.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
  border-color: #956868;
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 6px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}

.tooltip-container:hover .tooltip {
  opacity: 1;
}


@media screen and (min-width: 768px) {
  .ant-picker-panel-container {
    position: absolute;
    left: -187px;
  }
}

@media screen and (max-width: 768px) {
  .ant-picker-dropdown .ant-picker-panel-container .ant-picker-panel-layout {
    width: 288px;
    overflow-x: auto;
  }
}

.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-selected
  .ant-picker-cell-inner {
  background-color: #9c6742;
}

.ant-picker-range,
.ant-picker:hover,
.ant-picker-range:hover {
  border-color: #956868;
}

.ant-picker-focus:hover {
  border-color: #956868;
}

.text-gray {
  color: #6b7280;
}

.text-gray-50-hover:hover {
  color: #ffffff !important;
}

.text-gray-50 {
  color: #d2d5db !important;
}

.text-gray-100 {
  color: #a4a9b3 !important;
}

.text-coffee {
  color: #9c6742;
}

.text-coffee-100 {
  color: #956868;
}

.text-light-coffee {
  color: #d7c5b9;
}

.border-gray {
  border-color: #d1d5db;
}

.border-gray-50 {
  border-color: #e5e7eb !important;
}

.border-coffee {
  border-color: #9c6742;
}

.bg-gray {
  background-color: #d2d5db;
}

.bg-coffee {
  background-color: #9c6742;
}

.bg-coffee-100 {
  background-color: #956868;
}
.ant-badge .ant-badge-multiple-words{
  padding: 0 25px;
}

.ant-badge .ant-badge-count{
  height: 25px;
  font-size: 17px;
  line-height: 24px;
}

.button-block-flex{
  display: flex; 
  flex-direction: column; 
  align-items: center;
}

.bg-coffee-900 {
  background-color: rgba(156, 103, 66, 0.1);
}

.bg-light-coffee {
  background-color: rgba(156, 103, 66, 0.1);
}

.bg-green {
  background-color: #5bd08a;
}

.bg-gray-50 {
  background-color: rgba(196, 196, 196, 0.16) !important;
}

.bg-gray-50-hover:hover {
  background-color: rgba(196, 196, 196, 0.16) !important;
  color: white;
}

.rounded-4 {
  border-radius: 4px;
}

input:checked + div {
  border-color: #9c6742;
}

input:checked + div svg {
  display: block;
}

input[type='radio']:checked + span {
  border-width: 5px;
  border-color: #9c6742;
}

.box-btn-radio input[type='radio']:checked + span + span {
  color: #9c6742;
  font-weight: 500;
}

.shadow-box {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 0px 12px rgba(0, 0, 0, 0.06);
}

.ScrollbarsCustom-TrackY {
  background-color: transparent !important;
  width: 8px !important;
  top: 0 !important;
  height: 100% !important;
  border-radius: 0 !important;
}

.ScrollbarsCustom-ThumbY {
  width: 5px !important;
  background-color: #9d6743 !important;
  transition: all 0.1s ease-in-out;
  margin-left: auto;
  border-radius: 0 !important;
}

.ScrollbarsCustom-TrackY:hover,
.ScrollbarsCustom-TrackY:hover .ScrollbarsCustom-ThumbY,
.ScrollbarsCustom-ThumbY.dragging {
  width: 8px !important;
}

.single-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.side-nav {
  padding-top: 63px;
}

.side-nav .ScrollbarsCustom-ThumbY {
  background-color: #d7c5b9 !important;
}

.tab-content {
  display: none;
}

.tab-content.active {
  display: block;
}

.modal-open {
  -webkit-filter: blur(5px);
  -ms-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  filter: blur(5px);
  position: relative;
}

.modal-open:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 9;
}

.modal-dialog .ScrollbarsCustom-Content {
  display: flex;
  align-items: center;
}

.loader:before,
.loader-logged-in:before {
  content: '';
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
  transition: all 1s ease-in-out;
}

.loader-logged-in:before {
  left: 15rem;
}

.swiper-pagination-h .swiper-pagination-bullet {
  width: auto;
  height: auto;
  background-color: transparent;
  padding: 15px;
  color: #6b7280;
  border-top: 2px solid transparent;
  border-radius: 0;
  opacity: 1;
}

.swiper-pagination-h .swiper-pagination-bullet.swiper-pagination-bullet-active {
  border-color: #9c6742;
}

.overflow-hidden .ScrollbarsCustom-Scroller {
  overflow: hidden !important;
}

.break a {
  padding: 15px 5px;
  display: block;
}

.rotate-90 {
  transform: rotate(90deg);
}
